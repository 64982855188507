<template>
  <div class="container">
        <article class="percorso" :class="'percorso--'+percorso.categoria" v-if="percorso">
        <div class="percorso__numero" v-if="percorso.numero">{{ percorso.numero }}</div>
        <div class="flex responsive">
            
            <div class="col col-sm-7">
                    <h2 v-if="percorso.titolo" class="percorso__titolo">{{ percorso.titolo }} <span v-if="percorso.sottotitolo">{{ percorso.sottotitolo }}</span></h2>
                    <div v-if="percorso.categoria == 'tipo1'" class="percorso__classi">Percorso per I e II classi Scuola Primaria</div>
                    <div v-if="percorso.categoria == 'tipo2'" class="percorso__classi">Percorso per III, IV e V classi Scuola Primaria</div>
                    <div v-if="percorso.categoria == 'tipo3'" class="percorso__classi">Percorso per I, II e III classi Scuola Secondaria di primo grado</div>
                    <div v-if="percorso.testo" v-html="percorso.testo" class="percorso__testo"></div>

                    <button @click="OpenModalRegistrazione" class="button button--light percorso__button" v-if="allowIscrizioni">Iscrivi la tua classe</button>
            </div>

            <div class="col col-sm-4 col-offset-sm-1">
                <div v-if="percorso.obbiettivo" class="percorso__obiettivo"><strong>Obiettivo specifico:</strong> {{ percorso.obbiettivo }}</div>
            </div>

            <div class="percorso_mascotte">
                <img src="@/assets/imgs/mascotte-tipo1.svg" v-if="percorso.categoria == 'tipo1'"/>
                <img src="@/assets/imgs/mascotte-tipo2.svg" v-if="percorso.categoria == 'tipo2'"/>
                <img src="@/assets/imgs/mascotte-tipo3.svg" v-if="percorso.categoria == 'tipo3'"/>
            </div>

        </div>
   </article>

   <transition name="bounce">
            <div v-if="modalRegistrazione" class="modal">

                <div class="modal--header" v-if="!readPrivacy">
                    <h4 class="modal--title">Registrazione al percorso <strong>{{ percorso.titolo }}, {{ percorso.sottotitolo }}</strong></h4>
                    <button @click="modalRegistrazione = !modalRegistrazione" class="icon--button m-l-auto">
                        <svg class="icon" width="36" height="36"><use xlink:href="#close" /></svg>
                    </button>
                </div>
                <div class="modal--header" v-else>
                    <h4 class="modal--title">Informativa Privacy</h4>
                </div>

                <div class="modal--content" v-if="!readPrivacy">

                    <div v-if="success">
                        <div class="alert alert--success">
                            Richiesta inviata!
                        </div>
                    </div>

                    <ErrorsAlert />

                
                    <form v-if="form && loading != true" class="form">  
                        <fieldset>
                            <legend>Classe</legend>
                            <div class="alert alert--warning"><strong>Attenzione:</strong> è possibile iscrivere ogni classe ad un solo percorso</div>
                            
                            <div class="field-group flex">
                                <div class="field col col-sm-7">
                                    <label>Classe <span class="required">*</span></label>
                                    <select v-model="classe" required>
                                        <optgroup label="Scuola Primaria">
                                            <option :disabled="percorso.categoria != 'tipo1'">Prima</option>
                                            <option :disabled="percorso.categoria != 'tipo1'">Seconda</option>
                                            <option :disabled="percorso.categoria != 'tipo2'">Terza</option>
                                            <option :disabled="percorso.categoria != 'tipo2'">Quarta</option>
                                            <option :disabled="percorso.categoria != 'tipo2'">Quinta</option>
                                        </optgroup>
                                        <optgroup label="Scuola Secondaria di primo grado">
                                            <option :disabled="percorso.categoria != 'tipo3'">Prima</option>
                                            <option :disabled="percorso.categoria != 'tipo3'">Seconda</option>
                                            <option :disabled="percorso.categoria != 'tipo3'">Terza</option>
                                        </optgroup>
                                    </select>
                                </div>

                                <div class="field col col-6 col-sm-2">
                                    <label>Sezione <span class="required">*</span></label>
                                    <input type="text" v-model="sezione" required>
                                </div>

                                <div class="field col col-6 col-sm-2">
                                    <label>N° studenti <span class="required">*</span></label>
                                    <input type="text" v-model="numero_studenti" required>
                                </div>
                            </div>

                             <div class="field-group flex">
                                    <div class="field">
                                        <label>
                                            <input type="checkbox" v-model="ha_partecipato"> La classe ha già partecipato alle precedenti edizioni?
                                        </label>
                                    </div>
                            </div>

                            <div class="field-group flex item-align-center">
                                <div class="field">
                                    <label>
                                        La classe preferisce svolgere il percorso <strong>in presenza</strong> o <strong>da remoto</strong> <span class="required">*</span>
                                    </label>
                                </div>
                                <div class="field">
                                    <select v-model="modalita">
                                        <option value="" disabled selected>--</option>
                                        <option value="presenza">In presenza</option>
                                        <option value="online">Da remoto</option>
                                    </select>
                                </div>
                            </div>

                        </fieldset>

                        <fieldset>
                            <legend>Scuola</legend>
                            
                            <div class="field-group flex">
                                <div class="field col col-sm-6">
                                    <label>Scuola/plesso <span class="required">*</span></label>
                                    <input type="text" v-model="scuola" required>
                                </div>
                                <div class="field col col-sm-6">
                                    <label>Istituto <span class="required">*</span></label>
                                    <input type="text" v-model="istituto" required>
                                </div>
                             </div>

                             <div class="field-group flex">
                                 <div class="field col-sm-6">
                                    <label>Comune <span class="required">*</span></label>
                                    <select v-model="comune" required v-if="getComuni">
                                        <option v-for="comune in getComuni" :key="comune.nome" :value="comune.nome">
                                            {{ comune.nome }}
                                        </option>
                                    </select>
                                 </div>
                                 <div class="field col-sm-6">
                                    <label>Località</label>
                                    <input type="text"  v-model="localita" required>
                                 </div>
                             </div>

                             <div class="field-group flex">
                                 <div class="field col-10">
                                    <label>Indirizzo <span class="required">*</span></label>
                                    <input type="text"  v-model="indirizzo" required>
                                 </div>
                                 <div class="field col-2">
                                    <label>N° <span class="required">*</span></label>
                                    <input type="text"  v-model="numero_civico" required>
                                 </div>
                             </div>

                        </fieldset>

                        <fieldset>
                            <legend>Contatto per comunicazioni</legend>
                            
                            <div class="field-group flex">
                                    <div class="field col-12">
                                        <label>Insegnante referente <span class="required">*</span></label>
                                        <input type="text"  v-model="referente" required>
                                    </div>
                            </div>

                            <div class="field-group flex">
                                 <div class="field col-7">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email"  v-model="email" required>
                                 </div>
                                 <div class="field col-5">
                                    <label>Telefono <span class="required">*</span></label>
                                    <input type="tel" v-model="telefono" required>
                                 </div>
                             </div>

                              <div class="field-group flex">
                                 <div class="field col-12">
                                    <label>Note</label>
                                    <textarea v-model="note" ></textarea>
                                 </div>
                             </div>

                        </fieldset>
                        <div class="alert alert--default"><strong>Attenzione:</strong> per l'invio è necessario accettare l'informativa privacy</div>
                        <label class="privacy">
                            <input type="checkbox" v-model="privacy" name="privacy"> Acconsento, ai sensi e per gli effetti degli artt. 7 e 13 del Regolamento Europeo n. 679/16, al trattamento dei dati personali, secondo le modalità e limiti indicati nell'<a href="#" @click.prevent="readPrivacy = true" target="_blank">informativa privacy</a> <span class="required">*</span>
                        </label>
                        
                        <input type="hidden" name="nomepercorso" v-model="nomepercorso">
                        <input type="hidden" name="tiposcuola" v-model="tiposcuola">

                    </form>
                    <Loading v-if="loading"/>
                </div>
                <div v-else>
                    <PrivacyPolicy />
                </div>

                <div class="modal--footer" v-if="!readPrivacy">
                    <button class="button" :disabled="privacy == false" @click="inviaRichiesta()" v-if="form  && loading != true">Invia richiesta</button>
                </div>
                <div class="modal--footer" v-else>
                    <button class="button button--gray m-l-auto" @click="readPrivacy = false">Chiudi</button>
                    <button class="button m-r-auto" @click="readPrivacy = false, privacy = true">Accetta</button>
                </div>

            </div>
        </transition>
        <transition name="fade">
            <div v-if="modalRegistrazione" class="overlay"></div>
       </transition>
        
</div>
</template>


<script>

import ErrorsAlert from '@/components/ErrorsAlert.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PreviewPercorso',
  data () {
    return {
      modalRegistrazione: false,
      form: true,
      readPrivacy: false,
      privacy: false,
      loading: false,
      success: false,
      // campi del form
      classe: '',
      sezione: '',
      numero_studenti: '',
      ha_partecipato: false,
      modalita: '',
      scuola: '',
      istituto: '',
      comune: '',
      localita: '',
      indirizzo: '',
      numero_civico: '',
      referente: '',
      email: '',
      telefono: '',
      note: '',
      startIscrizioni: '2024-09-16',
      endIscrizioni: '2024-10-31'
    }
  },

    watch: {
        modalRegistrazione: function(val) {
            if(val == true) {
                document.querySelector('body').classList.add('fixed')
            }else {
                document.querySelector('body').classList.remove('fixed')
            }
        }
  },

  props: {
    percorso: Object
  },

  computed: {
        tiposcuola: function () {
            return (this.percorso.categoria == 'tipo1' || this.percorso.categoria == 'tipo2') ? 'scuola primaria' : 'scuola secondaria'
        },

        nomepercorso: function () {
            return this.percorso.titolo
        },

         ...mapGetters('comuni',[
        'getComuni',
        ]),

        allowIscrizioni: function () {
            const date = new Date().toJSON().slice(0, 10);
            return date >= this.startIscrizioni &&  date <= this.endIscrizioni;
        }
   },

  components: {
    ErrorsAlert,
    PrivacyPolicy,
    Loading
  },

  methods: {

      ...mapActions('comuni',[
        'fetchComuni',
     ]),

      OpenModalRegistrazione() {
          this.modalRegistrazione = true
          this.$store.dispatch('comuni/fetchComuni')
      },

      CloseModalRegistrazione() {
          this.modalRegistrazione = false
          document.body.classList.remove('fixed')
      },

      inviaRichiesta () {
                this.$store.state.errors = null
                this.loading = true
                this.disabled = true
                this.$store.dispatch('registrazione', {
                classe: this.classe + this.sezione,
                tipo_scuola: this.tiposcuola,
                numero_studenti: this.numero_studenti,
                ha_partecipato: this.ha_partecipato == true ? 'Ha già partecipato' : 'Non ha partecipato' ,
                modalita: this.modalita,
                scuola: this.scuola,
                istituto: this.istituto,
                comune: this.comune,
                indirizzo: this.indirizzo,
                localita: this.localita,
                civico: this.numero_civico,
                referente: this.referente,
                email: this.email,
                telefono: this.telefono,
                note: this.note,
                nome_percorso: this.nomepercorso
                })
                .then((response) => {
                this.loading = false
                this.success = true
                this.form = false
                }).catch(error => {
                this.loading = false
                this.$store.state.errors = error.response.data
                })

      }
      
  },

  beforeDestroy() { 
        
  },

}
</script>

<style>
    .privacy {
        font-size: 1rem !important;
    }
</style>