<template>
  <div class="home">
    <section class="testata">
      <div class="container flex items-center">
        <div class="col col-sm-6 col-md-5">
          <h1><span class="nowrap">Ri-creazione</span><br />2024-2025</h1>
          <h2>Da oggetto a rifiuto… e ritorno. <br />La via delle 4 R.</h2>
          <a href="#percorsi" class="button" v-smooth-scroll
            >Scopri i percorsi</a
          >
        </div>
        <div class="col col-sm-6 col-md-6">
          <img src="@/assets/imgs/testata3.svg" alt="Ri-creazione" />
        </div>
      </div>
    </section>

    <section class="chiusura" v-if="4 == 5">
      Conclusa la sesta edizione di “RI-creazione”, il progetto per le scuole di
      Sei Toscana promosso anche nell’anno della pandemia.
      <a
        href="https://seitoscana.it/comunicati/09062021-si-conclude-la-sesta-edizione-ri-creazione-progetto-le-scuole-sei-toscana-promosso-anche-nell-anno-della-pandemia"
        target="_blank"
        >Leggi comunicato stampa</a
      >
    </section>

    <section class="chiusura new" v-if="oggi <= '2024-10-31'">
      <strong>Sono aperte le iscrizioni alla decima edizione di Ri-Creazione!  <br/> 
        Le iscrizioni saranno possibili fino al 31 ottobre 2024. <br/>
        Per partecipare clicca sul bottone “ISCRIVI LA TUA CLASSE” in un percorso a scelta tra quelli elencati qui sotto.
      </strong>
     <!--Clicca sul percorso scelto per iscrivere la tua classe-->
    </section>

    <section class="chiusura" v-if="oggi > '2026-10-31'">
      Il 31 ottobre si sono chiuse le iscrizioni per l’anno scolastico 2024/2025. <br/>
      Nelle prossime settimane le classi iscritte riceveranno conferma di partecipazione
    </section>

    <section class="intro" id="progetto">
      <div class="container flex">
        <div class="col col-sm-5">
          <h2 class="section__title">Il progetto</h2>
          <p>
            Sei un insegnante? Il
            <strong
              >progetto “Ri-creazione” è stato pensato e costruito proprio per
              te!</strong
            >
          </p>
          <p>
            Giunto alla decima edizione, il progetto di educazione ambientale
            di Sei Toscana propone alle scuole primarie e secondarie di primo
            grado percorsi educativi per approfondire l’argomento dei rifiuti e
            del ciclo dei materiali, per incentivare la diffusione di buone
            pratiche quotidiane a casa e a scuola e ridurre l’impatto
            <strong>dei nostri comportamenti quotidiani</strong>.
          </p>
          <p>
            Con l’introduzione dell’insegnamento scolastico dell’educazione
            civica, il progetto potrà avvalersi sia di unità didattiche di
            singoli insegnamenti che di moduli interdisciplinari trasversali
            condivisi da più docenti.
            <strong
              >Il progetto potrà inserirsi nella programmazione scolastica con
              l’obiettivo di sviluppare negli studenti una riflessione sugli
              obiettivi dell’Agenda 2030 dell’ONU </strong
            >e sull’importanza della salvaguardia del territorio e dell’ambiente
            con il fine di creare cittadini consapevoli, non solo dei diritti,
            dei doveri e delle regole di convivenza, ma anche delle sfide del
            presente e dell’immediato futuro.
          </p>
        </div>
        <div class="col col-sm-3 col-offset-sm-1">
          <img
            alt="Ri-creazione"
            src="../assets/imgs/LogoRicreazione.svg"
            class="logo"
          />
          <p class="t-center" style="padding-top: 1rem">
            <strong><em>La partecipazione al progetto è completamente gratuita.
                L’iscrizione è consentita solo agli insegnanti.</em
              ></strong
            >
          </p>
        </div>
      </div>
    </section>

    <section v-if="getPercorsi" id="percorsi" class="percorsi">
      <div class="container flex tems-center row-reverse">
        <div class="col col-sm-6 col-offset-sm-1">
          <h2 class="section__title">I percorsi</h2>
          <p>
            I percorsi educativi, finalizzati all’approfondimento di una o più
            delle “4R” del ciclo dei rifiuti (Riduzione, Riutilizzo, Riciclo,
            Recupero) e impostati in chiave inter e multidisciplinare, sono
            rivolti alle scuole primarie e secondarie di primo grado dell’ATO
            Toscana Sud - province di Arezzo, Siena e Grosseto e dei Comuni
            della Val di Cornia (LI). All’interno di ciascun gruppo l’insegnante
            potrà scegliere uno dei percorsi tematici proposti, in base alla
            propria programmazione didattica.
          </p>
        </div>

        <div class="col col-sm-5">
          <img alt="Devices" src="../assets/imgs/devices.svg" class="devices" />
        </div>
      </div>
      <PercorsoPreview
        v-for="percorso in getPercorsi"
        :key="percorso.id"
        :percorso="percorso"
      />
    </section>

    <section class="contatti" id="informazioni">
      <div class="container flex items-center row-reverse responsive">
        <div class="col col-sm-5 col-offset-sm-1">
          <h2 class="section__title">Informazioni e contatti</h2>
          <p>Per informazioni: scuola@seitoscana.it oppure 05771799173.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import PercorsoPreview from "@/components/PercorsoPreview.vue";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "Home",

  components: {
    LoginForm,
    PercorsoPreview,
  },

  computed: {
    ...mapGetters("percorsi", ["getPercorsi"]),

    oggi: function () {
        const date = new Date().toJSON().slice(0, 10);
        return date;
    }
      
  },

  methods: {
    ...mapActions("percorsi", ["fetchPercorsiHome"]),
  },

  beforeMount() {
    this.$store.dispatch("percorsi/fetchPercorsiHome");
  },
};
</script>
 <style scoped>
.chiusura {
  background: #dd3c98;
  padding: 2rem;
  min-height: auto !important;
  margin: 0;
  display: block;
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  line-height: normal;
}

.chiusura.new {
  background: #51a0d8;
}

.chiusura a {
  color: white;
}

h1 {
  position: relative;
}
</style>
